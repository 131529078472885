.corporative {
  @include image-set(bg-corporative, bg-corporative, $background-darker);

  background-size: cover;
  background-repeat: no-repeat;
  color: white;

  p:last-child {
    padding-bottom: 40px;
  }
}

.corporative__items{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.corporative__item {
  position: relative;
  margin-bottom: 24px;
}

.corporative__title {
  position: relative;
  font-size: $subtitle-font-size;
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 40px;
  &:before {
    position: absolute;
    content: '';
    background-image: url('../img/check-02.svg');
    background-size: 40px,40px;
    width: 40px;
    height: 40px;
    left: 0;
    top: -8px;
  }
}

.course-page .corporative__title {
  font-size: initial;
}

.corporative-hidden {
  display: none;
}

.corporative__offer-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .field {
    margin-bottom: 20px
  }

  .form_input-send {
    width: 100%;
    margin-bottom: 20px
  }
}

@include bp(tablet) {
  .corporative__offer {
    margin-bottom: 30px;

    p {
      font-size: $subtitle-font-size;
      margin: 0 0 30px;
    }
  }

  .corporative__item {
    flex-basis: 45%;
  }

  .corporative__offer-form {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    max-width: unset;
    justify-content: space-around;

    h3 {
      flex-grow: 1;
    }

    .form_item:not(.form_item_policy-item) {
      width: 251px;
      margin: 0;
    }

    .form_item_policy-item {
      flex-basis: 100%;
      text-align: center;
    }
  }
}