html {
  background: white;
}

.primary-bg {
  background: $primary-bg-color
}

.secondary-bg {
  background: $secondary-bg-color
}

//.grn {
//  background: $tertiary-back-color
//}

.llc-grad {
  background: #C4B0FC;
  background: linear-gradient(180deg, #C0AEFB 0%, #D7C4FF 73.26%, #EADFFF 100%);
  @include bp-mobile-first(tablet) {
    background: linear-gradient(180deg, #C4B0FC 0%, #C4B0FC calc(100% - 145px), #DDCAFF calc(100% - 145px), #DDCAFF 100%);
  }

}