.material__item {
  background: white;
  margin-bottom: 12px;
  cursor: pointer;

  ul {
    padding-bottom: 12px;
  }
}

.material__title {
  padding: 10px 15px;
  font-weight: 700;
}

@include bp(tablet) {
  .material__title {
    padding-right: 60px;
  }
}
@include bp(notebook) {
  .material__item {
    &:hover,
    &:focus {
      box-shadow: 0 0 5px #ccc;
    }
  }
}