.feedback__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 30px;
}

.feedback__list-item {
  padding: 15px 20px;
  margin: 0;
  background: white;
  box-shadow: 0 0 7px #ccc;
  width: calc(100% - 20px*2);
  margin-bottom: 20px;
}

.feedback__quote-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.feedback__quote-block div:first-child {
  text-align: left;
  margin-bottom: 40px;
}
.feedback__quote-block div:last-child {
  flex-grow: 1;
  text-align: left;
  max-width: 545px;
}
.feedback__quote-block p {
  padding: 0 1em;
}

.feedback__author-block {
  font-size: $font-size-small;
}

@include bp(tablet) {
  .feedback__list {
    flex-direction: row;
  }
  .feedback__list-item {
    width: 320px;
    margin-bottom: 0;
  }

}
