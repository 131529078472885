.lower-form__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 25px 15px;

  .field {
    margin-bottom: 30px
  }

  .form_input {
    background: white;
  }
};

@include bp(tablet) {
  .lower-form__form {

    .field {
      width: 75%;
      margin-top: 0;
    }

    .form_item-send {
      width: 75%;
      margin-top: 0;
    }

    .form_input-send {
      width: 100%;
    }
    //.form_item {
    //  margin-top: 0;
    //}
  }
}
