.modal {
  &-header {
    min-height: 71px;
  }

  &-title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    line-height: 1.35em;
  }

  .btn-close {
    margin-top: -26px;
  }

  &-little-sign {
    font-size: 12px;
    text-align: center;
    color: $secondary-text-color;
  }
}