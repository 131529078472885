@mixin bp($size) {

  $mobile-wide: ('min-width: 400px');
  $mobile-horisontal: ('min-width: 520px');
  $tablet: ('min-width: 768px');
  $tablet-wide: ('min-width: 960px');
  $desktop-small: ('min-width: 1200px');
  $desktop-large: ('min-width: 1400px');

  @if $size == mobile-wide {
    @media (min-width: 400px) { @content; }
  }
  @else if $size == mobile-horisontal {
    @media (min-width: 520px) { @content; }
  }
  @else if $size == tablet {
    @media (min-width: 768px)  { @content; }
  }
  @else if $size == notebook {
    @media (min-width: 992px) { @content; }
  }
  @else if $size == desktop-small {
    @media (min-width: 1200px) { @content; }
  }
  @else if $size == desktop-large {
    @media (min-width: 1400px) { @content; }
  }
}

@mixin bp-mobile-first($size) {

  $mobile-wide: ('min-width: 400px');
  $mobile-horisontal: ('min-width: 520px');
  $tablet: ('min-width: 768px');
  $tablet-wide: ('min-width: 960px');
  $desktop-small: ('min-width: 1200px');
  $desktop-large: ('min-width: 1400px');
  $desktop-extralarge: ('min-width: 1600px');

  @if $size == mobile-wide {
    @media (min-width: 400px) { @content; }
  }
  @else if $size == mobile-horisontal {
    @media (min-width: 520px) { @content; }
  }
  @else if $size == tablet {
    @media (min-width: 768px)  { @content; }
  }
  @else if $size == notebook {
    @media (min-width: 992px) { @content; }
  }
  @else if $size == desktop-small {
    @media (min-width: 1200px) { @content; }
  }
  @else if $size == desktop-large {
    @media (min-width: 1400px) { @content; }
  }
  @else if $size == desktop-extralarge {
    @media (min-width: 1600px) { @content; }
  }
}


@mixin image-set($filename, $path: '', $gradient: 0, $device: desktop) {
  @if $gradient == 0 {
    background-image: url('../img/' + $path + '/' + $filename + '-' + $device + '-1x.jpg');
    background-image: image-set('../img/' + $path + '/' + $filename + '-' + $device + '-1x.jpg' 1x, '../img/' + $path + '/' + $filename + '-' + $device + '-2x.jpg' 2x)
  }

  @else {
    background-image: $gradient, url('../img/' + $path + '/' + $filename + '-' + $device + '-1x.jpg');
    background-image: $gradient, image-set('../img/' + $path + '/' + $filename + '-' + $device + '-1x.jpg' 1x, '../img/' + $path + '/' + $filename + '-' + $device + '-2x.jpg' 2x);
  }
}
