#scrollup {
  position: fixed;
  opacity: .9;
  right: 5px;
  bottom: 5px;
  display: none;
  cursor: pointer;
  float: right;
  min-height: 35px;
  padding: 14px 1px 12px;
  width: 59px;
  border: 1px solid #f16521;
  border-radius: 3px;

  img {
    display: block;
    margin: 0 auto;
  }

  .vverh {
    margin: 8px 0 0;
    color: #f16521;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
}
