.modules {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 40%;
    height: 2px;
    background: #005952;
    top: 0;
    left: 30%;
  }
}

.vebinars-about__item {
  background: $primary-bg-color;
  margin-bottom: 12px;
  cursor: pointer;

  ol>li {
    padding-bottom: 12px;
  }
}

.vebinars-about__subtitle {
  margin: -19px auto 23px;
  font-size: 0.9em;

  @include bp(mobile-horisontal) {
    margin: -39px auto 45px;
    font-size: 1em;
    max-width: 777px;
  }
}

.vebinars-about__title {
  padding: 10px 15px;
  font-weight: 700;
}

.vebinars-about__list {
  padding-left: 30px;
}

@include bp(tablet) {
  .vebinars-about__title {
    padding-right: 60px;
  }
}
@include bp(notebook) {
  .vebinars-about__item {
    &:hover,
    &:focus {
      box-shadow: 0 0 5px #ccc;
    }
  }
}