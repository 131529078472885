.features {
  position: relative;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 40%;
    height: 2px;
    background: $primary-elem-color;
    top: 100%;
    left: 30%;
  }
}

.features__items{
  display: grid;
  justify-content: center;
  gap: 20px 10%;
  font-size: 12px;
  text-align: center;
  grid-template-columns: repeat(1, 1fr);

  @include bp(tablet) {
    grid-template-columns: repeat(2, 1fr);
    font-size: 16px;
    text-align: left;
    gap: 40px 10%;
  }

  @include bp(notebook) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 5%;
  }
}


.features__item {
  position: relative;
  margin-bottom: 24px;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    background: darken($secondary-bg-color, 20%);
    width: 85%;
    height: 1px;
    left: calc(50% - 42.5%);
    top: calc(100% + 12px);
  }

  a {
    font-size: $font-size-small;
    color: $secondary-elem-color;
    outline: none;

    &:hover {
      color: darken($secondary-elem-color, 7%);
      text-decoration: underline;
    }
  }
}

.features__title {
  font-size: $subtitle-font-size;
  margin-top: 0;
  margin-bottom: 20px;
}

@include bp(tablet) {
  .features__item {
    flex-basis: 45%;

    &:not(:last-child):after {
      width: 0;
      height: 0;
    }

    &:nth-child(even):before {
      content: '';
      position: absolute;
      background: darken($secondary-bg-color, 20%);
      width: 1px;
      height: 130px;
      left: -13%;
      top: calc(50% - 65px);
    }
  }
}

@include bp(notebook) {
  .features__item {
    flex-basis: 30%;

    &:nth-child(even):before {
      width: 0;
      height: 0;
    }

    &:nth-child(3n+2):before,
    &:nth-child(3n+2):after {
      content: '';
      position: absolute;
      background: darken($secondary-bg-color, 20%);
      width: 1px;
      height: 85%;
      left: -12%;
      top: calc(50% - 42.5%);
    }

    &:nth-child(3n+2):after {
      left: 105%;
    }
  }

  .features__item:last-child:after {
    width: 0;
    height: 0;
  }
}
