.format {
  &__card {
    border-color: $secondary-elem-color;
    position: relative;
  }

  .distance-format,
  .corporative-format,
  .online-format {
    position: relative;
    padding-left: 50px;
    line-height: 37px;
    vertical-align: center;
    margin-bottom: 20px;

    &:before {
      position: absolute;
      content: '';
      background-image: url(../img/icons/format-corporate.svg);
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 37px;
      height: 37px;
      background-position: center;
      background-size: contain;
    }
  }

  .distance-format:before {
    background-image: url(../img/icons/format-distance.svg);
  }

  .online-format:before {
    background-image: url(../img/icons/format-online.svg);
  }
}