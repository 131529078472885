.offer44fz {
  display: flex;
  position: relative;
}

.offer44fz__box {
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
  //margin: -$section-height-margin 0;
  //padding-top: 36px;
  //  //padding-bottom: 36px;
  padding: $section-height-margin 10px;
  width: 100%;
  @include bp(tablet) {
    width: 50%;
    background: transparent;
    z-index: 0;
    padding: $section-height-margin 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .orange {
    color: $secondary-elem-color
  }

  .small {
    font-size: 0.8em;
  }
}

.offer44fz__pic-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  hEIGHT: 100%;
}

.offer44fz__pic {
  object-fit: cover;
  object-position: right center;
  height: 100%;
  width: 100%;

  @include bp-mobile-first(desktop-extralarge) {
    object-position: center;
  }
}

.offer44fz__list {
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 22px;
    padding-bottom: 15px;
    &:before {
      position: absolute;
      content: '';
      background-image: url('../img/check-02.svg');
      background-size: 18px,18px;
      width: 18px;
      height: 18px;
      left: 0;
      top: 0;
    }
  }
}

.offer44fz__mention {
  font-size: 0.8em;
  margin-top: 0;
}




@include bp(tablet) {
  .offer44fz {
    padding: 0;
  }

  .offer44fz__title {
    text-align: left;
  }

  .offer44fz__pic-container {
    position: relative;
    width: 50%;
    height: auto;
  }
}
