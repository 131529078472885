.offer__item {
  position: relative;
  padding: 10px 20px;
  border: 1px solid $primary-bg-color;
  margin-bottom: 40px;
  text-align: left;
  
  &.hidden {
    display: none;
  }

  @include bp-mobile-first(tablet) {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
  }

  @include bp-mobile-first(notebook) {
    flex-basis: 48%;
    margin-bottom: 35px;
  }

  @include bp-mobile-first(desktop-small) {
    flex-basis: 49%;
    margin-bottom: 25px;
  }
}

.course-page .offer__item {
  border-color: $primary-elem-color;
}

.offer__title {
  margin-bottom: 0;
}

.offer__subtitle {
  font-size: $subtitle-font-size;
  text-align: center;
  margin: 0 0 $titles-bottom-margin;
}

.offer__lector {
  font-weight: 700;
}

.offer__event-name {
  color: lighten($primary-elem-color, 3%);
  font-weight: 700;
  padding-bottom: 20px;
}

.offer__date {
  font-size: $font-size-small;
}

.offer__more {
  font-size: $font-size-small;
  color: $secondary-elem-color;
  outline: none;

  &:hover {
    color: darken($secondary-elem-color, 7%);
    text-decoration: underline;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.offer__spoiler-details {
  display: block;
  width: 100%;
  text-align: center;
}

.offer__spoiler-button {
  display: inline-block;
  color: $primary-elem-color;
  cursor: pointer;
  text-align: center;
  position: relative;
  font-weight: bolder;
}

.offer__spoiler-details .offer__spoiler-button:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  border-bottom: dashed 2px;
}


.offer__spoiler-details[open] .offer__spoiler-button {
  margin-bottom: 30px;
}

@include bp(tablet) {
  .offer__spoiler-items-wrapper,
  .offer__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .offer__event-name {
    flex-grow: 1;
  }
}


.primary-bg .offer__item {
  border: 1px solid #005952;
  background: #fff;
  border-radius: 10px;
}