.header {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $primary-bg-color;
  display: flex;
  flex-wrap: nowrap;

  @include bp-mobile-first(notebook) {
    padding: 0 20px;
    justify-content: space-between;
  }

  &__mobile-menu-toggler {
    height: 30px;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    padding-left: 20px;
    display: flex;
    flex: 0 0 30px;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: content-box;

    &[aria-expanded=true] {
      justify-content: center;

      span:nth-child(1) {
        transform: rotate(45deg);
      }

      span:nth-child(2) {
        display: none;
      }

      span:nth-child(3) {
        margin-top: -3px;
        transform: rotate(-45deg);
      }
    }

    span {
      background: #727271;
      height: 3px;
      transition: all 0.2s linear;
    }
  }

  &__nav {
    flex-grow: 1;

    @include bp-mobile-first(notebook) {
      flex-wrap: wrap;
      flex-grow: unset;
    }

    @include bp-mobile-first(desktop-large) {
      flex-wrap: nowrap;
      flex-grow: 1;
    }
  }

  &__menu-wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;
    background-color: #ebebeb;
    z-index: 7;
    order: 1;
    width: 100vw;

    @include bp-mobile-first(notebook) {
      background-color: $primary-bg-color;
      justify-content: flex-start;
      top: 100%;
      left: -20px;
    }

    @include bp-mobile-first(desktop-large) {
      position: static;
      width: unset;
      background-color: transparent;
      justify-content: center;
    }
  }

  &_nav__link {
    padding: 10px 17px;
    display: block;
    transition: all .2s linear;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    font-weight: 700;

    &.header_nav__link_green {
      color: #45bd9b;
    }

    &.header_nav__link_yellow {
      color: #fcb040;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    .logo__image {
      width: 139px;
      padding-bottom: 10px;
    }

    .logo__link {
      font-size: .65em;
      font-weight: 700;
      line-height: 13px;
    }

    @include bp-mobile-first(notebook) {
      flex-grow: unset;
    }
  }

  &__application {
    display: flex;
    align-items: center;
    padding-right: 20px;

    @include bp-mobile-first(notebook) {
      padding-right: 0;
    }
  }

  &__btn,
  &__phone span {
    display: none;

    @include bp-mobile-first(notebook) {
      display: inline-block;
    }
  }

  &__btn {
    padding: 4px 10px;
    font-size: 16px;
    margin: 0 0 0 30px;
    border-radius: 6px;
    font-weight: 700;
  }

  &__phone {
    margin-bottom: 0;
    a {
      color: #222;
      text-decoration: none;
      text-indent: -999px;
      overflow: hidden;
      width: 16px;
      height: 26px;
      display: block;
      background: url(../img/spritesheet.png) -650px -5px no-repeat;
      padding-left: 10px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;


      @include bp-mobile-first(notebook) {
        background: 0 0;
        text-indent: 0;
        width: auto;
        height: auto;
        vertical-align: bottom;
      }
    }
  }
}

