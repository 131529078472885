.btn{
  display: block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid rgb(0, 66, 65);
  white-space: nowrap;
  margin: 0 auto;
  padding: 1em 2.6em;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.42857143;
  border-radius: 5px;
  user-select: none;
  background-color: rgb(0, 89, 82);
  color: #fff;

  &:hover {
    background-color: rgb(0, 66, 65);
    color: #fff;
  }

  &.btn-secondary {
    border: solid 1px $secondary-elem-color;
    background: $secondary-elem-color;
    color: black;
    &:hover {
      background: darken($secondary-elem-color, 10%);
    }
  }

  &.btn_mini {
    padding: 6px 12px;
  }

  &.btn_short {
    min-width: 120px;
  }

  &.btn_middle {
    min-width: 180px;
  }

  &.btn_wide {
    min-width: 320px;
  }

  &.btn_content-wide {
    width: 100%;
  }
}



