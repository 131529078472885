.process__wrapper {
  max-width: 1140px;
}


.process__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.process__item {
  position: relative;
  flex-basis: 80%;
  text-align: center;
  padding-bottom: 80px;
  box-sizing: content-box;
  &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 52px;
    top: calc(100% - 75px);
    left: calc(50% - 2px);
    background-image: url("../img/icons/circle.svg");
    background-size: contain;
    background-repeat: round;
  }
}


.process__item-title {
  position: relative;
  font-size: $subtitle-font-size;
  padding-top: 74px;
  font-weight: 700;
  &:before {
    content: '';
    position: absolute;
    width: 56px;
    height: 56px;
    top: 0;
    left: calc(50% - 33px);
    background-image: url("../img/icons/icon-4.svg");
    background-size: cover;
    border: 6px solid transparent;
    outline: 2px solid black;
    border-radius: 50%;
    background-repeat: no-repeat;
  }
}

.process__item:first-child {
  .process__item-title:before {
    background-image: url("../img/icons/icon-1.svg");
  }
}

.process__item:nth-child(2) {
  .process__item-title:before {
    background-image: url("../img/icons/icon-2.svg");
  }
}

.process__item:nth-child(3) {
  .process__item-title:before {
    background-image: url("../img/icons/icon-3.svg");
  }
}

.process__item:nth-child(6) {
  .process__item-title:before {
    background-image: url("../img/icons/icon-5.svg");
  }
}

.process__item:last-child {
  padding-bottom: 0;
  &:before,
  &:after {
    background-image: unset;
  }

  .process__item-title:before {
    background-image: url("../img/icons/icon-6.svg");
  }
}

@include bp(notebook) {
  .process__item {
    flex-basis: 16%;
    max-width: 180px;
    padding-bottom: 0;
    padding-right: 80px;
    order: 0;

    &:after {
      height: 4px;
      width: 71px;
      left: calc(100% - 75px);
      top: calc(50% - 2px);
      background-image: url("../img/icons/circle2.svg");
    }

    &:before {
      left: calc(100% - 36px);
      top: calc(50% - 18px);
      transform: rotate(-90deg);
    }
  }

  .process__item:nth-child(4) {
    padding-bottom: 40px;
    padding-right: 0;

    &:after {
      background-image: unset;
    }
  }

  .process__item:nth-child(5),
  .process__item:nth-child(6) {
    padding-right: 100px;

    &:after {
      width: 90px;
      left: calc(100% - 86px)
    }
  }

  .process__item:last-child {
    padding-right: 0;
  }

  .process_long {
    .process__item {
      flex-basis: 25%;
      max-width: 265px;
      padding-bottom: 20px;
    }

    .process__item:nth-child(3) {
      padding-right: 0;

      &:after {
        background-image: unset;
      }
    }

    .process__item:nth-child(4),
    .process__item:nth-child(5) {
      padding-right: 80px;

      &:after {
        width: 71px;
        left: calc(100% - 75px);
      }
    }

    .process__item:nth-child(6) {
      padding-right: 0;
    }

    .process__item:nth-child(4):after {
      background-image: url("../img/icons/circle2.svg");
    }
  }
}

