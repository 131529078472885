.timetable__card {
  display: flex;
  background: $tertiary-bg-color;
  padding: 16px 19px;
  flex-direction: column;
  max-width: 370px;
  margin: 0 auto 20px;
  position: relative;
  border-radius: 5px;

  @include bp(notebook) {
    padding: 24px 30px;
    max-width: 530px;
  }

  .timetable__card-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    margin: -16px -19px 0;
    padding: 16px 19px;
    background: lighten($secondary-elem-color, 11%);

    @include bp(notebook) {
      margin: -24px -30px 0;
      padding: 24px 30px;
    }
  }

  p:first-of-type {
    margin-top: 30px;
  }

  .timetable__card-subtitle {
    font-size: .9em;
    color: #666;
    margin-bottom: 40px;
    min-height: 30px;
  }

  .timetable__card-button {
    margin-bottom: 0;
  }
}