.form {
  margin: 0 auto;
  position: relative;
  color: #333;

  &.form_single {
    border-radius: $primary-radius;
    padding: 39px 33px;
    background: white;
  }

  &.form-horizontal {
    .form__field {
      @include bp(desktop-small) {
        width: 33%;
        margin-bottom: 5px;
      }
    }

    .form__item-send {
      margin-bottom: 20px;
    }

    .form__item-send,
    .form__input-send{
      width: 100%;

      @include bp(desktop-small) {
        width: unset;
        margin-bottom: 0;
      }
    }


  }

  &.form-horizontal.form-corporative {
    .form__item-send,
    .form__input-send {
      width: 100%;
    }
  }

  &.form_wide-limited {
    max-width: 360px;
  }

  &.form_captcha-size {
    max-width: 368px;
  }


  &.lower-form_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0 25px 15px;

    h3 {
      padding-bottom: 15px;
    }

    .form__field,
    .form_item-send {
      width: 100%;
    }

    @include bp-mobile-first(tablet) {
      max-width: 600px;
      .form__field,
      .form_item-send {
        width: 75%;
      }
    }
  }

  &.visible-form {
    .form__item-send {
      width: 100%;
    }
  }

  &__field {
    margin-bottom: 20px;
    width: 100%;
  }

  &__input {
    width: 100%;
    color: #787878;
    font-size: 18px;
    background-color: inherit;
    border: solid 1px #4f4f4f;
    padding: 6px 15px;
    border-radius: 6px;
  }

  &_item-send {
    text-align: center;
  }

  &__input-send {
    cursor: pointer;
  }

  .personal-data-header {
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;}

  .personal-data-show {
    text-decoration: none;
    color: inherit;
    border-bottom: dashed 1px;
    cursor: pointer;
  }

  .form_item__policy_text {
    margin-top: 9px;
    font-size: 12px;
  }

  .hint-block {
    position: absolute;
    max-width: 231px;
    padding: 13px 9px;
    background-color: #f3dede;
    border-radius: 10px;
    top: calc(50% - 85px);
    left: calc(100% - 251px);

    &:after {
      content: '';
      border: 12px solid transparent;
      border-top: 12px solid #f3dede;
      position: absolute;
      left: 50%;
      margin-left: -12px;
      bottom: -24px;
      width: 0;
    }
  }
}