.faq__item {
  background: white;
  margin-bottom: 12px;
  cursor: pointer;

  p {
    padding: 0 30px 20px;
  }
}

.faq__title {
  padding: 10px 15px;
  font-weight: 700;
}

@include bp(tablet) {
  .faq__title {
    padding-right: 60px;
  }
}
@include bp(notebook) {
  .faq__item {
    &:hover,
    &:focus {
      box-shadow: 0 0 5px #ccc;
    }
  }
}