body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $primary-text-color;
  font-size: 16px;
  line-height: 1.2;

  @include bp-mobile-first(mobile-horisontal) {
    font-size: 16px;
    line-height: 1.33em;
  }
}

.section-title {
  font-size: 18px;
  font-weight: 600;

  @include bp-mobile-first(mobile-horisontal) {
    font-weight: 800;
    font-size: 32px;
  }
}

.section-title__pretitle {
  display: inline-flex;
  align-items: center;
  font-size: 0.8em;
  color: rgb(235, 68, 71);
  font-weight: 400;
}

.section-subtitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 56px;

  @include bp-mobile-first(mobile-horisontal) {
    font-size: 22px;
  }
}

.section-post-subtitle {
  text-align: center;
  color: $secondary-text-color;
  margin-bottom: 58px;
  font-size: 14px;

  @include bp-mobile-first(mobile-horisontal) {
    font-size: 18px;
  }
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 18px;
  font-weight: 700;

  @include bp-mobile-first(mobile-horisontal) {
    font-size: 28px
  }

  @include bp-mobile-first(tablet) {
    font-size: 32px;
  }
}

h3 {
  font-size: 16px;
  font-weight: 100;


  @include bp-mobile-first(mobile-horisontal) {
    font-size: 24px
  }

  @include bp-mobile-first(tablet) {
    font-size: 22px;
  }
}

h2,
h3 {
  margin-top: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 19px;

  @include bp-mobile-first(mobile-horisontal) {
    padding-bottom: 40px;
  }
}

h4 {
  font-size: 1em;
  font-weight: 700;
}

a {
  background-color: transparent;
  color: $primary-link-color;
  text-decoration: underline;
}

li {
  margin-bottom: 0.25em;
}

p {
  display: block;
  max-width: 100%;
}


.link-no-link {
  text-decoration: none;
  color: inherit;

  &_mobile {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    outline: none;
    text-decoration: none;
    color: inherit;
  }
}

.link_orange_small {
  font-size: $font-size-small;
  color: $secondary-elem-color;
  outline: none;

  &:hover {
    color: darken($secondary-elem-color, 13%);
    text-decoration: underline;
  }
}

.link_gready {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}