.visible-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .visible-form__item {
    width: 100%;
    margin-bottom: 24px;

    label {
      margin-top: 0;
    }
  }


  .visible-form_input-send {
    text-transform: uppercase;
    white-space: normal;
    width: 100%;
    font-size: 16px;
    padding: 4px 12px;
  }

  .field {
    margin-bottom: 30px
  }

  .form_input {
    background: white;
  }

  .visible-form__item-send {
    margin-bottom: 0;
  }


  .visible-form__privacy-policy .privacy-policy__label {
    @include bp(desktop-small) {
      text-align: center;
    }
    
    &:before {
      @include bp(desktop-small) {
        left: calc(50% - 210px);
      }
    }    
  } 
  
  
  &.visible-form_center {
    @include bp(tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      align-items: center;
      max-width: 800px;
      width: 100%;
    }

    .visible-form__item:not(.visible-form__item-send, .visible-form__privacy-policy) {
      width: 30%;
      margin-right: 10px;
    }

    .visible-form__privacy-policy .privacy-policy__label {
      @include bp(tablet) {
        text-align: center;
      }

      &:before {
        @include bp(tablet) {
          left: calc(50% - 210px);
        }
      }
    }
  }

  &.visible-form_right-oriented {
    .visible-form__item-send {
      margin-bottom: 30px;
    }

    @include bp(tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      max-width: unset;
    }

    .visible-form__item:not(.visible-form__item-send, .visible-form__privacy-policy) {
      @include bp(tablet) {
        min-width: 45%;
      }

      @include bp(desktop-small) {
        width: 48%;
        min-width: unset;
      }

      @include bp(desktop-large) {
        width: 24%
      }
    }

    .visible-form__item-send {
      @include bp(desktop-large) {
        width: 50%;
        min-width: unset;
      }
    }
  }

  &.visible-form_slim {
      @include bp(tablet) {
        margin: 0 auto;
        width: 75%;
        max-width: 500px;
      }
  }
}
