.main-banner {
  display: block;
  height: auto;
  color: white;
  min-height: 31vh;

  &.main-banner_bg {
    @include image-set(bg-main, backgrounds-main-banner, $background-darker);
    background-position: 80% top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @include bp-mobile-first(desktop-small) {
    min-height: 57vh;
  }

  .main-banner__title {
    @include bp-mobile-first(tablet) {
      font-size: 2.4em;
      margin: .67em 0;
      font-weight: 700;
    }
  }

  &:not(.main-banner_course):not(.main-banner_offer) {
    @include bp-mobile-first(tablet) {
      .main-banner__title {
        font-size: 2.8em;
      }
    }
  }

  &.main-banner_offer.main-banner_bg {
    @include image-set(bg-main, backgrounds-main-banner, $background-darker, mobile);
    background-position: 69% top;

    @include bp-mobile-first(tablet) {
      @include image-set(bg-main, backgrounds-main-banner, $background-darker, tablet);
      background-position: 35% top;
    }

    @include bp-mobile-first(desktop-small) {
      @include image-set(bg-main, backgrounds-main-banner, $background-darker, desktop);
    }
  }

  &__subtitle {
    font-size: 1.3em;
    line-height: 1.5em;
  }

  &__order-wrapper {
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 30px;
  }

  &__price {
    font-size: 1.2em;
    margin-right: 10%;

    @include bp-mobile-first(notebook) {
      font-size: 2.4em;
    }
  }

  &__course-order {
    align-self: unset;
    margin: 0;
    padding: 0.70em 0;
    min-width: 170px;
  }


  &__course-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        position: absolute;
        content: '';
        background-image: url("../img/check-01.svg");
        background-size: 25px, 25px;
        width: 25px;
        height: 25px;
        left: 0;
      }
    }
  }
}

#course1-page .main-banner_bg {
  @include image-set(coursename-1, coursename-1, $background-darker);
}

#course2-page .main-banner_bg {
  @include image-set(coursename-2, coursename-2, $background-darker);
}

#course3-page .main-banner_bg {
  @include image-set(coursename-3, coursename-3, $background-darker);
}

#course4-page .main-banner_bg {
  @include image-set(coursename-4, coursename-4, $background-darker);
}

#course5-page .main-banner_bg {
  @include image-set(coursename-5, coursename-5, $background-darker);
}

#course6-page .main-banner_bg {
  @include image-set(coursename-6, coursename-6, $background-darker);
}

#course7-page .main-banner_bg {
  @include image-set(coursename-7, coursename-7, $background-darker);
}


//стили для дубля главной страницы
.main-banner_offer {
  padding: 25px 0;

  &.main-banner_bg {
    background-position: 35% top;
  }

  @include bp-mobile-first(tablet) {
    padding: 75px 0 55px;
  }

  @include bp-mobile-first(desktop-small) {
    padding: 135px 0 95px;
  }

  .main-banner__title {
    font-size: 1.9em;
    line-height: 1.5;

    @include bp-mobile-first(desktop-small) {
      font-size: 2.8em;
      line-height: 1.3;
      max-width: 579px;
    }
  }

  .main-banner__subtitle {
    .mini {
      font-size: 0.9em;
    }

    .green {
      color: $elcode-accent-color;
    }

    .micro {
      font-size: 0.63em;
      line-height: 1.2em;
    }
  }
}
