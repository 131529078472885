details summary::-webkit-details-marker {
  display: none;
}

details > summary {
  list-style: none
}


summary {
  position: relative;
  &:focus {
    outline: none
  }
}

.rotating-cross {
  position: absolute;
  right: 15px;
  top: calc(50% - 18px);
  width: 20px;
  height: 20px;
  opacity: 0.3;
  padding: 8px;
  pointer-events: none;
  transition: transform .6s;
  box-sizing: content-box;
}

summary:hover .rotating-cross {
  opacity: 0.8;
  background-color: rgba(0,0,0,.1);
  border-radius: 50%;
}


.rotating-cross:before,
.rotating-cross:after {
  position: absolute;
  left: 2px;
  content: '';
  height: 20px;
  width: 2px;
  background-color: #333;
  @include bp(tablet) {
    left: 17px
  }
}


.rotating-cross:before {
  transform: rotate(90deg)
}


details[open] .rotating-cross {
  transform: rotate(45deg)
}


@media screen and (max-width: 765px) {
  .rotating-cross{
    display: none
  }
}


