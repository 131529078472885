.who-cares__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.who-cares__item {
  width: 90%;
  margin-bottom: 30px;
}

.who-cares__title {
  position: relative;
  font-size: $subtitle-font-size;
  margin-bottom: 24px;
  font-weight: 700;
  box-sizing: border-box;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: black;
    top: calc(100% + 10px);
    left: 0;
  }
}

.who-cares__item:not(:only-child) .who-cares__title {
  min-height: 80px;
}


@include bp(tablet) {
  .who-cares__items {
    justify-content: space-between;
  }

  .who-cares__item {
    width: 43%
  }

  .who-cares__item:only-child {
    width: 70%;
    margin: 0 auto;
  }
}

@include bp(notebook) {
  .who-cares__item {
    min-width: 23%;
    max-width: 35%;
    margin: 0 35px 0;
  }

  .who-cares__items {
    justify-content: center;
    flex-wrap: nowrap;
  }
}

.who-cares__items-list{
  margin: 0 auto;
  width: fit-content;
  max-width: 900px;
}

.who-cares__items-list-item {
  margin-bottom: 15px;
  line-height: 1.25em;
  @include bp(tablet) {
    font-size: 18px;
  }
}