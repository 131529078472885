.footer {
  display: flex;
  flex-wrap: wrap;
}

.contact {
  width: 100%;
}

.contact_map {
  height: 400px;
  width: auto;
  background: #f4f0d3;
}

.contact_list {
  /*margin: 0 auto -185px;*/
  position: relative;
  z-index: 2;
  padding-left: 0;
}

.contact_item_vcard {
  position: absolute;
  width: 320px;
  padding: 10px;
  padding-bottom: 30px;
  margin: 0 auto 10px;
  background-color: hsla(0, 100%, 100%, 0.75);
  left: 50%;
  margin-left: -160px;
  margin-top: 19px;
}


.footer__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  font-size: $subtitle-font-size;
}

.privacy-policy-show {
  font-size: 0.8em;
  color: #333;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 4px;

  &:hover {
    cursor: pointer;
    color: #666
  }
}

@include bp(notebook) {
  .contact {
    width: 50%;
  }

  .footer__wrap {
    width: 45%;
    padding: 0;
    padding-left: 30px;
  }
}
