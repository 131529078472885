.checkbox__control {
  display: none;
  &:checked + .checkbox__label {
    &::before {
      content: '✔';
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #0bb888;
    }
  }
}

.checkbox__label {
  text-align: left;
  margin-top: 11px;
  margin-bottom: 0;
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: 35px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.2px;

  @include bp(mobile-wide) {
    padding-left: 45px;
  }

  @include bp(tablet) {
    padding-left: 30px;
    font-size: 15px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #4f4f4f;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
