$tick-size: 25px;
$tick-size-mobile: 19px;

.tag-container {
  //margin-bottom: 30px;
  max-width: 428px;
  margin: 0 auto 30px;
}

.courses__switcher-tag {
  background-color: $primary-bg-color;
  padding: 8px 16px;
  border-radius: 25px;
  border-color: transparent;
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 13px;

  &:hover {
    box-shadow: 0 0 0 1px $primary-elem-color,
  }

  &:active {
    background-color: darken($primary-bg-color, 9%);
  }

  &.active {
    background-color: $primary-elem-color;
    color: #fff;
    font-weight: 700;
  }
}

.courses__cards_container {
  display: block;
}

.courses__card {
  display: flex;
  border: 1px solid $primary-text-color;
  padding: 12px;
  flex-direction: column;
  max-width: 417px;
  margin: 0 auto 20px;
  position: relative;

  &.hidden {
    display: none
  }

  &.courses__card_secondary {
    background-image: none;
    padding: 12px;
    margin-bottom: 20px;

    &:not(.courses__card_slider) {
      @include bp(tablet) {
        max-width: 60%;
      }

      @include bp(desktop-small) {
        max-width: 339px;
        margin-bottom: 40px;
      }
    }
  }

  &.courses__card_slider {
    max-width: unset;
  }
}

.courses__card-info {
  flex-grow: 1;
}

.courses__card-title {
  margin: 0;
  margin-bottom: 20px;
}

.courses__card-subtitle {
  font-size: 0.9em;
  color: $secondary-text-color;
  margin-bottom: 30px;
  min-height: 30px;
}

.courses__card-marks {
  display: flex;
  align-items: center;
  list-style: none;
  background: darken($primary-bg-color, 35%);
  padding: 0 12px;
  margin: 0 -12px;
  margin-bottom: 10px;
  position: relative;

  &_orange {
    background: $secondary-elem-color;
  }

  &_special {
    background: #FC6C55;
  }

  .beginners,
  .special {
    position: absolute;
    font-size: 0.79em;
    top: -26px;
    left: 12px;
    padding-left: 0;

    &.slidingcourses-special {
      font-size: 0.83em
    }

    @include bp(mobile-wide) {
      font-size: 0.92em;
    }

    &:before {
      content: unset;
    }
  }

  .beginners {
    color: $secondary-elem-color;
  }

  .special {
    color: tomato;
  }

  li {
    padding-left: $tick-size;
    position: relative;
    color: white;
    line-height: 28px;
    margin-bottom: 0;

    &:before {
      position: absolute;
      content: '';
      background-image: url("../img/check-01.svg");
      background-size: $tick-size, $tick-size;
      width: $tick-size;
      height: $tick-size;
      left: 0;
    }

    &:last-child   {
      margin-left: 70px;
      &:after {
        position: absolute;
        display: inline-block;
        content: '';
        width: 3px;
        height: 17px;
        border-radius: 2px;
        background: #fff;
        left: -35px;
        top: calc(50% - 9px)
      }
    }
  }

  &.courses__card-marks_orange {
    background: $secondary-elem-color;
  }
}

.courses__special {
  margin-bottom: 20px;
}

.courses__card-offer-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.courses__card-price {
  color: tomato;
  font-size: 1.1em;
  font-weight: bold;
}

.courses__card-more {
  font-weight: bold;
  color: lightslategray;
  line-height: 1.5em;
  padding: 0 15px;
  border: 2px solid lightslategray;
  border-radius: 20px;
  text-decoration: none;
  &:hover {
    box-shadow: inset 0 0 20px #ccc;
    color: inherit;
  }
  &:not(.courses__card-more_button):before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.courses__card-more_button  {
    background: #FC6C55;
    border: 2px solid #FC6C55;
    border-radius: 5px;
    color: white;
    padding: 2px 15px;
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 13px #b72118;
    }
  }
}

.courses__slider {

  //.slick-slide {
  //  max-width: 297px;
  //}

  .courses__card_slide {
    padding: 0 7px;

    @include bp(tablet) {
      padding: 0 13px;
    }

    @include bp(desktop-small) {
      padding: 0 20px;
    }
  }

  .slidingcourses-special.special,
  .slidingcourses-features {
    font-size: 0.71em;

    @include bp(tablet) {
      font-size: 0.83em;
    }

    @include bp(desktop-small) {
      font-size: 0.91em;
    }
  }

  .slidingcourses-features {
    @media (max-width: 1200px) {
      padding-left: $tick-size-mobile;

      &:before {
        background-size: $tick-size-mobile, $tick-size-mobile;
        width: $tick-size-mobile;
        height: $tick-size-mobile;
      }
    }


    &:last-child {
      margin-left: 63px;

      @include bp(tablet) {
        margin-left: 70px;
      }
    }
  }
}

@include bp(tablet) {
  .tag-container {
    max-width: unset;
  }

  .courses__cards_container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
  }

  .courses__card {
    margin: 0;
    max-width: 370px;
  }

}

@include bp(notebook) {
  .courses__switcher-tag {
    padding: 4px 12px;
  }

  .courses__cards_container {
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}