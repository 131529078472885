.lectors__items {
  display: block;
}

.lectors__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;

  p:last-of-type {
    flex: 1;
    padding-bottom: 15px;
  }

  &:only-child {
    flex-basis: 45%;
  }
}

.lectors__name {
  font-weight: 700;
}

.speaker-photo {
  border-radius: 50%;
  width: 125px;
  height: 125px;
}

.kalyapicheva {
  background: url("../img/kalyapicheva.jpg") no-repeat 50% 50%;
}

.kazarina {
  background: url("../img/kazarina.jpg") no-repeat 50% 50%;
}

.kuzmin {
  background: url("../img/kuzmin.png") no-repeat 50% 50%;
}

.seleznev {
  background: url("../img/seleznev.jpg") no-repeat 50% 50%;
}

.troyakova {
  background: url("../img/troyakova.jpg") no-repeat 50% 50%;
}

.lectors__secondary-info {
  font-size: $font-size-small;
  color: $secondary-text-color;
  margin-bottom: 30px;
}

.speaker-title {
  padding: 20px;
  color: rgb(0, 131, 121);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  //border-bottom: 1px solid #e5e5e5;
}

.speaker-features-title {
  font-weight: bold;
}

.speaker-features-content {
  margin: 25px;
}

.speaker-features-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.lector__more {
  display: block;
  background: transparent;
  border: none;
  cursor: pointer;
  color: lighten($primary-elem-color, 9%);
  font-size: 14px;
  text-decoration: underline;
}

.sf-image-item {
  max-width: 28%;
}

.sf-image-item img {
  cursor: zoom-in;
  max-width: 100%;
}

@include bp(tablet) {
  .lectors__items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: relative;

    &:not(.single):after {
      position: absolute;
      display: block;
      content: '';
      background: rgba(60, 60, 60, 0.34);
      width: 65%;
      height: 1px;
      margin: 0 auto;
      top: 50%;
      left: 0;
      right: 0;
    }
  }

  .lectors__item {
    flex-basis: 35%;
    margin-bottom: 120px;
  }

  .lectors__item:last-of-type {
    margin-bottom: 0;
  }


  .sf-image-item {
    max-width: 24%;
    padding-bottom: 20px;
  }

  .sf-image-item:only-child {
    max-width: 70%;
  }
}

@include bp(desktop-small) {
  .lectors__items {
    position: static;

    &:after {
      content: none;
    }
  }

  .lectors__item {
    flex-basis: 28%;
    margin-bottom: 0;
  }
}
