.about {
  display: flex;
  position: relative;
}

.about__box {
  background: rgba(255, 255, 255,0.8);
  z-index: 2;
  margin: -$section-height-margin auto;
  padding-top: 36px;
  padding-bottom: 36px;
}

.about__pic-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  hEIGHT: 100%;
}

.about__pic {
  object-fit: cover;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 7px #ccc;
}

.about__list {
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding-left: 22px;
    padding-bottom: 15px;
    &:before {
      position: absolute;
      content: '';
      background-image: url('../img/check-02.svg');
      background-size: 18px,18px;
      width: 18px;
      height: 18px;
      left: 0;
      top: 0;
    }
  }
}

.licenses__container {
  display: flex;
  justify-content: space-around;
}

.licenses__item {
  max-width: 280px;
}

.letters {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 260px;
    top: calc(100% + 0.83em);
    left: calc(50% - 130px);
    background: #ccc;
  }
}

@include bp(tablet) {
  .about {
    padding: 0;
  }

  .about__title {
    text-align: left;
  }

  .about__box {
    background: transparent;
    z-index: 0;
    margin: $section-height-margin 0;
    padding-left: 50px;
  }

  .about__pic-container {
    position: relative;
    height: auto;
    width: 73%;
  }
}
